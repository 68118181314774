// colors
$theme-primary: #4cde96 !default;
$theme-info: #0a26fa !default;
$theme-link: #f7245c !default;
$theme-altLink-bg: #f7245c;
$theme-altLink-color: white;
$theme-text: #000e30 !default;
$theme-grey-light: hsl(0, 0%, 96%) !default;

// layout
$theme-column-gap: 1.5rem !default;
$tablet: 765px !default;
$desktop: 1024px !default;

// general elements
$theme-radius: 5px !default;
$theme-input-radius: 5px !default;
$theme-button-hover-box-shadow: 0 4px 3px 0 rgb(0 0 0 / 20%) !default;
$theme-button-padding-horizontal: 1.5em !default;

// font $ typgoraphy
$theme-font-family-primary: sans-serif !default;
$theme-title-color: $theme-text !default;
$theme-subtitle-color: $theme-text !default;

// several components

// progressbar
$theme-progress-border-radius: 0 !default;
$theme-progress-bar-background-color: #e6e6e6 !default;
$theme-progressbar-color: $theme-info !default;

// header menu
$theme-navbar-height: 3rem !default;
$theme-navbar-box-shadow-color: rgb(0 0 0 / 20%) !default;
$theme-navbar-item-hover-color: $theme-primary !default;
$theme-navbar-tab-hover-border-bottom-color: $theme-primary !default;
$theme-navbar-header-fontsize: 1rem !default;
$theme-navbar-header-font-weight: 600 !default;

//footer
$theme-footer-padding: 0 !default;

// action details block
$theme-action-details-block-bg: $theme-grey-light !default;
$theme-action-details-block-progressbar-color: $theme-text !default;
