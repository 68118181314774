.button {
  &:hover {
    box-shadow: $theme-button-hover-box-shadow;
  }
  &.is-inverted {
    padding-left: 0;
    padding-right: 0;
  }
  &.is-alt-link {
    background-color: $theme-altLink-bg !important;
    color: $theme-altLink-color !important;
    border-color: $theme-altLink-color !important;
  }
}

img {
  object-fit: cover;
}

.progress:not(:last-child) {
  margin-bottom: 0;
}

.action-details {
  .day-counter {
    @media (max-width: $tablet) {
      margin-top: -9rem;
      margin-bottom: -9rem;
    }
  }
  .remaining-amount span {
    color: $theme-action-details-block-progressbar-color;
  }
}

.overlay {
  position: relative;
}
.overlay:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($primary, 0.3);
}
