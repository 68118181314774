// ---colors---//
$theme-primary: #005eb8;
$theme-info: #005eb8;
$theme-link: #005eb8;
$theme-altLink-bg: #ffff00;
$theme-altLink-color: #393535;
$theme-secondary: #ffff00;
$theme-tertiary: #ffff00;
$theme-text: #393535;
$theme-grey-light: #f8f6f3;

// --layout---//
// $theme-column-gap: 1.5rem;
// $tablet: 765px;
$theme-radius: 2rem;

// ---font $ typgoraphy--- //
$theme-font-family-primary: 'Montserrat', sans-serif;
$theme-title-color: #393535;
$theme-subtitle-color: $theme-primary;

// ---several components--- //

// ---progressbar--- //
$theme-progress-border-radius: 0;
// $theme-progress-bar-background-color: #e1e1e1;
$theme-progressbar-color: $theme-secondary;

// ---header menu--- //
// $theme-navbar-height: 3rem;
// $theme-navbar-box-shadow-color: rgb(0 0 0 / 20%);
$theme-navbar-item-hover-color: $theme-primary;
$theme-navbar-tab-hover-border-bottom-color: $theme-primary;
// $theme-navbar-header-fontsize: 1rem;
// $theme-navbar-header-font-weight: 600;

//---footer--- //
// $theme-footer-padding: 0;

// ---action details block--- //
$theme-action-details-block-progressbar-color: white;
$theme-action-details-block-bg: $theme-primary;

.button.is-alt-link {
  border: none !important;
}
