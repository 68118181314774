.NgxEditor {
  height: 16rem;
  overflow-y: scroll;
  &__Content {
    h2 {
      font-size: $size-2;
      font-weight: bold;
    }
    h3 {
      font-size: $size-3;
    }
  }
}
