@import '../scss/variables';

.columns {
  margin-top: 0;
}
.page {
  padding-bottom: 4rem;
}

html {
  font-size: 12px;
  @media (min-width: $tablet) {
    font-size: 14px;
  }
  @media (min-width: $desktop) {
    font-size: 16px;
  }
}
