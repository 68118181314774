@import './bulma.scss';
@import './variables.scss';

.action-content {
  h2 {
    font-size: $size-3 !important;
    font-weight: bold !important;
    color: $title-color !important;
  }
  h3 {
    font-size: $size-4 !important;
    color: $title-color !important;
  }
}

.subtitle-color {
  color: $theme-subtitle-color;
  &:hover {
    color: $link;
  }
}

.button {
  font-weight: bold;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.ngx-editor-content {
  p {
    margin-block: 1rem;
  }
}
