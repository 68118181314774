/* You can add global styles to this file, and also import other style files */

@import 'scss/variables';
@import 'scss/bulma';

@import 'scss/base';
@import 'scss/components';
@import 'scss/layout';
@import 'scss/fonts';
@import 'scss/forms';
@import '~ngx-toastr/toastr';
